import { arrayify, joinSignature, splitSignature } from '@ethersproject/bytes'
import { IEncodeOptions, ISwapResponse, parse, UniversalRouter } from '@swapnet-xyz/sdk'
import { Percent } from 'eth-mainnet-few-sdk-core-2'
import { PermitSignature } from 'hooks/usePermitAllowance'
import { InterfaceTrade } from 'state/routing/types'

const SIGNATURE_LENGTH = 65
const EIP_2098_SIGNATURE_LENGTH = 64

interface IPermitWithSignature {
  permit: {
    details: {
      token: string
      amount: bigint
      expiration: bigint
      nonce: bigint
    }
    spender: string
    sigDeadline: bigint
  }
  signature: string
}

const chainId = 81457

const router = new UniversalRouter(
  chainId,
  '0x2C8754B44865002415dD5CEBa6Cd67258D1eCe2e', // universal router (proxy) deployed address - by Ringswap
  '0x000000000022d473030f116ddee9f6b43ac78ba3' // permit2 deployed address - by Uniswap
)

export function optimizeAndEncodeRingX(
  swapResponse: ISwapResponse,
  fewTrade: InterfaceTrade,
  options: {
    deadline: string
    inputTokenPermit?: PermitSignature
    recipientAddress?: string
    allowedSlippage: Percent // in bips
  }
): string {
  const outputIsNative = fewTrade.outputAmount.currency.isNative
  const inputIsNative = fewTrade.inputAmount.currency.isNative

  const routingPlan = parse(swapResponse) // parse swapnet API response

  let encodeOptions: IEncodeOptions & { inputTokenPermit?: IPermitWithSignature }

  if (inputIsNative) {
    encodeOptions = { wrapInput: true, slippageTolerance: 0.01 }
  } else if (options.inputTokenPermit) {
    let signature = options.inputTokenPermit.signature

    const length = arrayify(options.inputTokenPermit.signature).length
    // signature data provided for EIP-1271 may have length different from ECDSA signature
    if (length === SIGNATURE_LENGTH || length === EIP_2098_SIGNATURE_LENGTH) {
      // sanitizes signature to cover edge cases of malformed EIP-2098 sigs and v used as recovery id
      signature = joinSignature(splitSignature(options.inputTokenPermit.signature))
    }

    console.log(options.inputTokenPermit, signature, 'new options.inputTokenPermit, signature')
    const permitWithSignature: IPermitWithSignature = {
      permit: {
        details: {
          token: options.inputTokenPermit.details.token,
          amount: BigInt(options.inputTokenPermit.details.amount.toString()),
          expiration: BigInt(options.inputTokenPermit.details.expiration.toString()),
          nonce: BigInt(options.inputTokenPermit.details.nonce.toString()),
        },
        spender: options.inputTokenPermit.spender,
        sigDeadline: BigInt(options.inputTokenPermit.sigDeadline),
      },
      signature,
    }

    if (outputIsNative) {
      encodeOptions = { unwrapOutput: true, inputTokenPermit: permitWithSignature, slippageTolerance: 0.01 }
    } else {
      encodeOptions = { inputTokenPermit: permitWithSignature, slippageTolerance: 0.01 }
    }
  } else {
    if (outputIsNative) {
      encodeOptions = { unwrapOutput: true, slippageTolerance: 0.01 }
    } else {
      encodeOptions = { slippageTolerance: 0.01 }
    }
  }

  const calldata = router.encode(routingPlan, encodeOptions) // use router object to encode calldata, with injected options
  return calldata
}
