import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { CurrencyAmount, Token } from 'eth-mainnet-few-sdk-core-2'
import { useWrappedTokenFromOriginal } from 'hooks/useWrappedToken'
import JSBI from 'jsbi'
import { useCallback, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { CountUp } from 'use-count-up'

import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import ClaimRewardModal from '../../components/earn/v2/ClaimRewardModal'
import SingleStakingModal from '../../components/earn/v2/SingleStakingModal'
import SingleUnstakingModal from '../../components/earn/v2/SingleUnstakingModal'
import { CardBGImage, CardNoise, CardSection, DataCard } from '../../components/earn/v2/styled'
import Row, { RowBetween } from '../../components/Row'
import { BIG_INT_SECONDS_IN_YEAR, BIG_INT_ZERO } from '../../constants/misc'
import { useCurrency } from '../../hooks/Tokens'
import { useColor } from '../../hooks/useColor'
import usePrevious from '../../hooks/usePrevious'
import useStablecoinPrice from '../../hooks/useStablecoinPrice'
import { useToggleWalletModal } from '../../state/application/hooks'
import { useCurrencyBalance } from '../../state/connection/hooks'
// import { useFewStakingInfo2 } from '../../state/stake/hooks'
import { useNewStakingTokenInfo, useStakingTokenInfo } from '../../state/stake/hooks'
import { ThemedText } from '../../theme'
import { currencyId } from '../../utils/currencyId'

const PageWrapper = styled(AutoColumn)`
  padding: 68px 8px 0px;
  max-width: 640px;
  width: 100%;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 48px 8px 0px;
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${
      showBackground ? theme.black : theme.deprecated_bg5
    } 100%) `};
`

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.deprecated_bg3};
  opacity: ${({ dim }) => (dim ? 0.4 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  height: 30px;
  width: fit-content;
  font-size: 16px;
  border-radius: 12px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 48%;
  `};
`

const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.deprecated_bg4};
  padding: 1rem;
  z-index: 1;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
  overflow: hidden;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.neutral1};
`

export default function SingleManage() {
  const { currencyIdA } = useParams<{ currencyIdA: string }>()
  const { account } = useWeb3React()
  const toggleWalletDrawer = useToggleAccountDrawer() // toggle wallet when disconnected

  // get currencies and pair
  const [currencyA] = [useCurrency(currencyIdA)]
  const { newPool } = useParams<{ newPool: string }>()

  const tokenA = (currencyA ?? undefined)?.wrapped
  const fewTokenA = useWrappedTokenFromOriginal(tokenA)

  const newStakingPool = useNewStakingTokenInfo(currencyIdA)?.[0]
  const oldStakingPool = useStakingTokenInfo(currencyIdA)?.[0]
  console.log(newStakingPool?.claimedAmount.toSignificant(), 'newStakingPool')
  console.log(oldStakingPool?.claimedAmount.toSignificant(), 'oldStakingPool')

  let governanceToken
  let stakingInfo

  switch (newPool) {
    case 'mainnet_pool':
      stakingInfo = newStakingPool
      governanceToken = 'POINT'
      break
    case 'newPool':
      stakingInfo = newStakingPool
      governanceToken = 'POINT'
      break
    case 'oldPool':
      stakingInfo = oldStakingPool
      governanceToken = 'RGB'
  }

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useCurrencyBalance(account ?? undefined, stakingInfo?.stakedAmount?.currency)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  // get the color of the token
  const token = fewTokenA
  // const WETH = WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA]
  // const fwETH = useWrappedTokenFromOriginal(WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA])
  const backgroundColor = useColor(token)
  // const token = currencyA?.isNative ? tokenB : tokenA
  // const WETH = currencyA?.isNative ? tokenA : tokenB
  // const fwETH = FEW_WRAPPED_NATIVE_CURRENCY[ChainId.BLAST_SEPOLIA]
  // const backgroundColor = useColor(token)

  // get WETH value of staked LP tokens
  // const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.token)
  const totalSupplyOfStakingToken = stakingInfo?.totalStakedAmount
  let valueOfTotalStakedAmountInOriginalToken: CurrencyAmount<Token> | undefined
  if (totalSupplyOfStakingToken && stakingInfo) {
    //TODO, fix usdc
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInOriginalToken = CurrencyAmount.fromRawAmount(
      stakingInfo?.originalToken?.wrapped,
      stakingInfo.totalStakedAmount.quotient
    )
  }
  // console.log(valueOfTotalStakedAmountInWETH?.toSignificant(), 'valueOfTotalStakedAmountInWETH')

  const countUpAmount = stakingInfo?.earnedAmount.subtract(stakingInfo?.claimedAmount)?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  const USDPrice = useStablecoinPrice(stakingInfo?.originalToken.wrapped)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInOriginalToken && USDPrice?.quote(valueOfTotalStakedAmountInOriginalToken)

  const toggleWalletModal = useToggleWalletModal()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <PageWrapper gap="lg" justify="center">
      <RowBetween style={{ gap: '24px' }}>
        <ThemedText.DeprecatedMediumHeader style={{ margin: 0 }}>
          <Row gap="8px">
            <Link to="/earn">
              <StyledArrowLeft />
            </Link>
            <Trans>{currencyA?.symbol} Liquidity Mining</Trans>
          </Row>
        </ThemedText.DeprecatedMediumHeader>
        <CurrencyLogo currency={currencyA ?? undefined} size="24px" />
        {/* <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={24} /> */}
      </RowBetween>

      <DataRow style={{ gap: '24px' }}>
        <PoolData>
          <AutoColumn gap="sm">
            <ThemedText.DeprecatedBody style={{ margin: 0 }}>
              <Trans>Total deposits</Trans>
            </ThemedText.DeprecatedBody>
            <ThemedText.DeprecatedBody fontSize={20} fontWeight={500}>
              {valueOfTotalStakedAmountInUSDC
                ? `$${valueOfTotalStakedAmountInUSDC.toFixed(2, { groupSeparator: ',' })}`
                : `${totalSupplyOfStakingToken?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ${
                    totalSupplyOfStakingToken?.currency.symbol
                  }`}
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </PoolData>
        <PoolData>
          <AutoColumn gap="sm">
            <ThemedText.DeprecatedBody style={{ margin: 0 }}>
              <Trans>Pool Rate</Trans>
            </ThemedText.DeprecatedBody>
            <ThemedText.DeprecatedBody fontSize={20} fontWeight={500}>
              {stakingInfo?.active ? (
                <Trans>
                  {stakingInfo.totalRewardRate?.multiply(BIG_INT_SECONDS_IN_YEAR)?.toFixed(2, { groupSeparator: ',' })}{' '}
                  {governanceToken} / year / {currencyA?.symbol}
                </Trans>
              ) : (
                <Trans>
                  0 {governanceToken} / year / {currencyA?.symbol}
                </Trans>
              )}
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </PoolData>
      </DataRow>

      {showAddLiquidityButton && (
        <VoteCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <ThemedText.DeprecatedWhite fontWeight={600}>
                  <Trans>Step 1. Get {totalSupplyOfStakingToken?.currency.symbol} from Ring Swap</Trans>
                </ThemedText.DeprecatedWhite>
              </RowBetween>
              <RowBetween style={{ marginBottom: '1rem' }}>
                <ThemedText.DeprecatedWhite fontSize={14}>
                  <Trans>
                    {totalSupplyOfStakingToken?.currency.symbol} is required. Once you&apos;ve get tokens from the Ring
                    Swap you can stake your
                    {' ' + totalSupplyOfStakingToken?.currency.symbol} on this page.
                  </Trans>
                </ThemedText.DeprecatedWhite>
              </RowBetween>
              <ButtonPrimary
                padding="8px"
                $borderRadius="8px"
                width="fit-content"
                as={Link}
                to={`/swap/?outputCurrency=${currencyA && currencyId(currencyA)}`}
              >
                <Trans>{`Get ${currencyA?.symbol}`}</Trans>
              </ButtonPrimary>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </VoteCard>
      )}

      {stakingInfo && (
        <>
          <SingleStakingModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <SingleUnstakingModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          />
          <ClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
            claimAmount={stakingInfo.earnedAmount.subtract(stakingInfo.claimedAmount)}
          />
        </>
      )}

      <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
            <CardSection>
              <CardBGImage desaturate />
              <CardNoise />
              <AutoColumn gap="md">
                <RowBetween>
                  <ThemedText.DeprecatedWhite fontWeight={600}>
                    <Trans>Your liquidity deposits</Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <ThemedText.DeprecatedWhite fontSize={36} fontWeight={600}>
                    {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                  </ThemedText.DeprecatedWhite>
                  <ThemedText.DeprecatedWhite>
                    <Trans>{currencyA?.symbol}</Trans>
                  </ThemedText.DeprecatedWhite>
                </RowBetween>
              </AutoColumn>
            </CardSection>
          </StyledDataCard>
          <StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}>
            <CardBGImage desaturate />
            <CardNoise />
            <AutoColumn gap="sm">
              <RowBetween>
                <div>
                  <ThemedText.DeprecatedBlack>
                    <Trans>Your unclaimed {governanceToken}</Trans>
                  </ThemedText.DeprecatedBlack>
                </div>
                {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.quotient) && (
                  <ResponsiveButtonPrimary onClick={() => setShowClaimRewardModal(true)} padding="2px 4px">
                    <Trans>Claim</Trans>
                  </ResponsiveButtonPrimary>
                )}
              </RowBetween>
              <RowBetween style={{ alignItems: 'baseline' }}>
                <ThemedText.DeprecatedLargeHeader fontSize={36} fontWeight={600}>
                  <CountUp
                    key={countUpAmount}
                    isCounting
                    decimalPlaces={4}
                    start={parseFloat(countUpAmountPrevious)}
                    end={parseFloat(countUpAmount)}
                    thousandsSeparator=","
                    duration={1}
                  />
                </ThemedText.DeprecatedLargeHeader>
                <ThemedText.DeprecatedBlack fontSize={16} fontWeight={500}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                    ⚡
                  </span>

                  {stakingInfo?.active ? (
                    <Trans>
                      {stakingInfo.rewardRate?.multiply(BIG_INT_SECONDS_IN_YEAR)?.toFixed(2, { groupSeparator: ',' })}{' '}
                      {governanceToken} / year
                    </Trans>
                  ) : (
                    <Trans>0 {governanceToken} / year</Trans>
                  )}
                </ThemedText.DeprecatedBlack>
              </RowBetween>
            </AutoColumn>
          </StyledBottomCard>
        </BottomSection>
        {/* <ThemedText.DeprecatedMain style={{ textAlign: 'center' }} fontSize={14}>
          <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
            ⭐️
          </span>
          <Trans>When you withdraw, the contract will automagically claim {governanceToken} on your behalf!</Trans>
        </ThemedText.DeprecatedMain> */}

        {!showAddLiquidityButton && (
          <DataRow style={{ marginBottom: '1rem' }}>
            {stakingInfo &&
              stakingInfo.active &&
              (!account ? (
                <ButtonPrimary padding="8px" $borderRadius="8px" width="160px" onClick={toggleWalletDrawer}>
                  <Trans>Connect Wallet</Trans>
                </ButtonPrimary>
              ) : (
                <ButtonPrimary padding="8px" $borderRadius="8px" width="160px" onClick={handleDepositClick}>
                  <Trans>{stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : 'Deposit Token'}</Trans>
                </ButtonPrimary>
              ))}

            {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
              <>
                <ButtonPrimary
                  padding="8px"
                  $borderRadius="8px"
                  width="160px"
                  onClick={() => setShowUnstakingModal(true)}
                >
                  <Trans>Withdraw</Trans>
                </ButtonPrimary>
              </>
            )}
          </DataRow>
        )}
        {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : !stakingInfo?.active ? null : (
          <ThemedText.DeprecatedMain>
            <Trans>
              {userLiquidityUnstaked.toSignificant(6)} {currencyA?.symbol} available
            </Trans>
          </ThemedText.DeprecatedMain>
        )}
      </PositionInfo>
    </PageWrapper>
  )
}
